import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerComponent } from './customer/customer.component';
import { AdminGuard } from './guards/admin.guard';
import { CustomerGuard } from './guards/customer.guard';
import { LoginGuard } from './guards/login.guard';
import { LandingPageModule } from './landing-page/landing-page.module';

const routes: Routes = [
  {path:'', loadChildren:() => LandingPageModule},
  {path:'auth',  canActivate:[LoginGuard], loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path:'' ,component:CustomerComponent, canActivate:[CustomerGuard], loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)},
  
  {path: 'admin',  canActivate:[AdminGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  {path:'verify-email/:uuid', loadChildren:() => import("./verify-email/verify-email.module").then(m => m.VerifyEmailModule)},
  {path:'**', redirectTo:'' }
  //{path:'' , canActivate:[LoginGuard], loadChildren: () => import('./frontend/frontend.module').then(m => m.FrontendModule)},

];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
