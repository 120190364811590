export const URLS = {
	getCountUrlBusiness: 'api/v1/dashboard/business/dashboard/stats',
	// getFileSubmittedBusiness: '/api/v1/dashboard/business/dashboard/file_request/month',
	getMyToolUsageBusiness: '/api/v1/dashboard/business/dashboard/file_request/tool',
	// getSupportTicketBusiness: '/api/v1/dashboard/business/dashboard/file_requests/status',
	getDynamicDrilldownBusiness: 'dashboard/business/dashboard/used_keys/month',

	getCountUrlIndividal: '/api/v1/dashboard/individual/stats',
	// getFileSubmittedIndividual: '/api/v1/dashboard/individual/file_requests/six/month',
	getFileSubmittedIndividualFilter: 'dashboard/individual/file_request/month?',
	
	getSupportTicketIndividual: '/api/v1/dashboard/individual/file_requests/status',
	getSupportTicketIndividualfilter: 'dashboard/individual/file_requests/status?month_number=',

	// getCardsResellerData: '/api/v1/dashboard/reseller/dashboard/stats',
	// getTunnedVechicleResseler: '/api/v1/dashboard/reseller/dashboard/file_tuned/month',
	getUnusedKeySubdealer: '/api/v1/customer/reseller/dealers/:uuid/?page=:pageNo&page_size=10',
	// resslerDynamicDrillDown: '/api/v1/dashboard/reseller/dashboard/used_keys/month',
	getAdminTechnicalTeamData: 'api/v1/dashboard/internal/technical/top_sub_dealers/month',
	getAdminFileTurnAroundTime: 'dashboard/internal/technical/month?',
	getTopSubDealerForReseller: `/api/v1/dashboard/reseller/top_sub_dealer`,
	// getAdminVechicleModel: '/api/v1/dashboard/internal/technical/file_by_model',
	getVehicleTunedType: '/api/v1/dashboard/internal/technical/vehicle_tuned/category',
	getToolUsage: 'dashboard/internal/technical/tool/usage?month='
}

export const monthList = [
	{ value: 1, name: 'January' },
	{ value: 2, name: 'February' },
	{ value: 3, name: 'March' },
	{ value: 4, name: 'April' },
	{ value: 5, name: 'May' },
	{ value: 6, name: 'June' },
	{ value: 7, name: 'July' },
	{ value: 8, name: 'August' },
	{ value: 9, name: 'September' },
	{ value: 10, name: 'October' },
	{ value: 11, name: 'November' },
	{ value: 12, name: 'December' },
]
export const chartColors = [
	"#3f7bc5", // Red
	"#33FF57", // Green
	"#FF33A1", // Pink
	"#FFFF33", // Yellow
	"#33FFFF", // Cyan
	"#9933FF", // Purple
	"#f54287", // Blue
	"#FF8333", // Orange
	"#33FF99", // Mint
	"#A133FF"  // Lavender
  ];
  
export const countryDetails = [
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1e8.svg",
		"country": "Ascension Island",
		"code": "ac"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1e9.svg",
		"country": "Andorra",
		"code": "ad"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ea.svg",
		"country": "United Arab Emirates",
		"code": "ae"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1eb.svg",
		"country": "Afghanistan",
		"code": "af"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ec.svg",
		"country": "Antigua & Barbuda",
		"code": "ag"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ee.svg",
		"country": "Anguilla",
		"code": "ai"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f1.svg",
		"country": "Albania",
		"code": "al"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f2.svg",
		"country": "Armenia",
		"code": "am"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f4.svg",
		"country": "Angola",
		"code": "ad"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f6.svg",
		"country": "Antarctica",
		"code": "aq"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f7.svg",
		"country": "Argentina",
		"code": "ar"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f8.svg",
		"country": "American Samoa",
		"code": "as"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f9.svg",
		"country": "Austria",
		"code": "at"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fa.svg",
		"country": "Australia",
		"code": "au"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fc.svg",
		"country": "Aruba",
		"code": "aw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fd.svg",
		"country": "Åland Islands",
		"code": "ax"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ff.svg",
		"country": "Azerbaijan",
		"code": "az"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e6.svg",
		"country": "Bosnia & Herzegovina",
		"code": "ba"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e7.svg",
		"country": "Barbados",
		"code": "bb"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e9.svg",
		"country": "Bangladesh",
		"code": "bd"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ea.svg",
		"country": "Belgium",
		"code": "be"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1eb.svg",
		"country": "Burkina Faso",
		"code": "bf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ec.svg",
		"country": "Bulgaria",
		"code": "bg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ed.svg",
		"country": "Bahrain",
		"code": "bh"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ee.svg",
		"country": "Burundi",
		"code": "bi"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ef.svg",
		"country": "Benin",
		"code": "bj"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f1.svg",
		"country": "St. Barthélemy",
		"code": "bl"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f2.svg",
		"country": "Bermuda",
		"code": "bm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f3.svg",
		"country": "Brunei",
		"code": "bn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f4.svg",
		"country": "Bolivia",
		"code": "bo"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f6.svg",
		"country": "Caribbean Netherlands"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f7.svg",
		"country": "Brazil",
		"code": "br"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f8.svg",
		"country": "Bahamas",
		"code": "bs"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f9.svg",
		"country": "Bhutan",
		"code": "bt"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fb.svg",
		"country": "Bouvet Island",
		"code": "bv"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fc.svg",
		"country": "Botswana",
		"code": "bw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fe.svg",
		"country": "Belarus",
		"code": "by"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ff.svg",
		"country": "Belize",
		"code": "bz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e6.svg",
		"country": "Canada",
		"code": "ca"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e8.svg",
		"country": "Cocos (Keeling) Islands",
		"code": "cc"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e9.svg",
		"country": "Congo - Kinshasa",
		"code": "cg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1eb.svg",
		"country": "Central African Republic",
		"code": "cf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ec.svg",
		"country": "Congo - Brazzaville",
		"code": "cd"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ed.svg",
		"country": "Switzerland",
		"code": "ch"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ee.svg",
		"country": "Côte d’Ivoire",
		"code": "ci"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f0.svg",
		"country": "Cook Islands",
		"code": "ck"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f1.svg",
		"country": "Chile",
		"code": "cl"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f2.svg",
		"country": "Cameroon",
		"code": "cm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f3.svg",
		"country": "China",
		"code": "cn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f4.svg",
		"country": "Colombia",
		"code": "co"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f5.svg",
		"country": "Clipperton Island",
		"code": "cp"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f7.svg",
		"country": "Costa Rica",
		"code": "cr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fa.svg",
		"country": "Cuba",
		"code": "cu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fb.svg",
		"country": "Cape Verde",
		"code": "cv"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fc.svg",
		"country": "Curaçao",
		"code": "cw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fd.svg",
		"country": "Christmas Island",
		"code": "cx"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fe.svg",
		"country": "Cyprus",
		"code": "cy"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ff.svg",
		"country": "Czechia",
		"code": "cz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ea.svg",
		"country": "Germany",
		"code": "de"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ec.svg",
		"country": "Diego Garcia",
		"code": "dg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ef.svg",
		"country": "Djibouti",
		"code": "dj"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f0.svg",
		"country": "Denmark",
		"code": "dk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f2.svg",
		"country": "Dominica",
		"code": "dm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f4.svg",
		"country": "Dominican Republic",
		"code": "do"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ff.svg",
		"country": "Algeria",
		"code": "dz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1e6.svg",
		"country": "Ceuta & Melilla",
		"code": "ea"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1e8.svg",
		"country": "Ecuador",
		"code": "ec"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ea.svg",
		"country": "Estonia",
		"code": "ee"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ec.svg",
		"country": "Egypt",
		"code": "eg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ed.svg",
		"country": "Western Sahara",
		"code": "eh"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f7.svg",
		"country": "Eritrea",
		"code": "er"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f8.svg",
		"country": "Spain",
		"code": "es"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f9.svg",
		"country": "Ethiopia",
		"code": "et"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ea-1f1fa.svg",
		"country": "European Union",
		"code": "eu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1eb-1f1ee.svg",
		"country": "Finland",
		"code": "fi"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1eb-1f1ef.svg",
		"country": "Fiji",
		"code": "fj"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f0.svg",
		"country": "Falkland Islands",
		"code": "fk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f2.svg",
		"country": "Micronesia",
		"code": "fm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f4.svg",
		"country": "Faroe Islands",
		"code": "fo"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f7.svg",
		"country": "France",
		"code": "fr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e6.svg",
		"country": "Gabon",
		"code": "ga"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e7.svg",
		"country": "United Kingdom",
		"code": "gb"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e9.svg",
		"country": "Grenada",
		"code": "gd"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ea.svg",
		"country": "Georgia",
		"code": "ge"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1eb.svg",
		"country": "French Guiana",
		"code": "gf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ec.svg",
		"country": "Guernsey",
		"code": "gg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ed.svg",
		"country": "Ghana",
		"code": "gh"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ee.svg",
		"country": "Gibraltar",
		"code": "gi"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f1.svg",
		"country": "Greenland",
		"code": "gl"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f2.svg",
		"country": "Gambia",
		"code": "gm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f3.svg",
		"country": "Guinea",
		"code": "gn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f5.svg",
		"country": "Guadeloupe",
		"code": "gp"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f6.svg",
		"country": "Equatorial Guinea",
		"code": "gq"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f7.svg",
		"country": "Greece",
		"code": "gr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f8.svg",
		"country": "South Georgia & South', Sandwich Islands",
		"code": "gs"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f9.svg",
		"country": "Guatemala",
		"code": "gt"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fa.svg",
		"country": "Guam",
		"code": "gu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fc.svg",
		"country": "Guinea-Bissau",
		"code": "gw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fe.svg",
		"country": "Guyana",
		"code": "gy"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f0.svg",
		"country": "Hong Kong SAR China",
		"code": "hk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f2.svg",
		"country": "Heard & McDonald Islands",
		"code": "hm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f3.svg",
		"country": "Honduras",
		"code": "hn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f7.svg",
		"country": "Croatia",
		"code": "hr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f9.svg",
		"country": "Haiti",
		"code": "ht"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ed-1f1fa.svg",
		"country": "Hungary",
		"code": "hu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1e8.svg",
		"country": "Canary Islands",
		"code": "ic"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1e9.svg",
		"country": "Indonesia",
		"code": "id"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1ea.svg",
		"country": "Ireland",
		"code": "ie"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f1.svg",
		"country": "Israel",
		"code": "il"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f2.svg",
		"country": "Isle of Man",
		"code": "im"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f3.svg",
		"country": "India",
		"code": "in"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f4.svg",
		"country": "British Indian Ocean Territory",
		"code": "io"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f6.svg",
		"country": "Iraq",
		"code": "iq"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f7.svg",
		"country": "Iran"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f8.svg",
		"country": "Iceland",
		"code": "is"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f9.svg",
		"country": "Italy",
		"code": "it"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ef-1f1ea.svg",
		"country": "Jersey",
		"code": "je"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f2.svg",
		"country": "Jamaica",
		"code": "jm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f4.svg",
		"country": "Jordan",
		"code": "jo"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f5.svg",
		"country": "Japan",
		"code": "jp"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ea.svg",
		"country": "Kenya",
		"code": "ke"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ec.svg",
		"country": "Kyrgyzstan",
		"code": "kg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ed.svg",
		"country": "Cambodia",
		"code": "kh"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ee.svg",
		"country": "Kiribati",
		"code": "ki"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f2.svg",
		"country": "Comoros",
		"code": "km"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f3.svg",
		"country": "St. Kitts & Nevis",
		"code": "kn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f5.svg",
		"country": "North Korea",
		"code": "kp"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f7.svg",
		"country": "South Korea",
		"code": "kr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1fc.svg",
		"country": "Kuwait",
		"code": "kw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1fe.svg",
		"country": "Cayman Islands",
		"code": "ky"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ff.svg",
		"country": "Kazakhstan",
		"code": "kz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e6.svg",
		"country": "Laos",
		"code": "la"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e7.svg",
		"country": "Lebanon",
		"code": "lb"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e8.svg",
		"country": "St. Lucia",
		"code": "lc"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1ee.svg",
		"country": "Liechtenstein",
		"code": "li"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f0.svg",
		"country": "Sri Lanka",
		"code": "lk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f7.svg",
		"country": "Liberia",
		"code": "lr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f8.svg",
		"country": "Lesotho",
		"code": "ls"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f9.svg",
		"country": "Lithuania",
		"code": "lt"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fa.svg",
		"country": "Luxembourg",
		"code": "lu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fb.svg",
		"country": "Latvia",
		"code": "lv"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fe.svg",
		"country": "Libya",
		"code": "ly"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e6.svg",
		"country": "Morocco",
		"code": "ma"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e8.svg",
		"country": "Monaco",
		"code": "mc"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e9.svg",
		"country": "Moldova",
		"code": "md"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ea.svg",
		"country": "Montenegro",
		"code": "me"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1eb.svg",
		"country": "St. Martin",
		"code": "mf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ec.svg",
		"country": "Madagascar",
		"code": "mg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ed.svg",
		"country": "Marshall Islands",
		"code": "mh"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f0.svg",
		"country": "North Macedonia",
		"code": "mk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f1.svg",
		"country": "Mali",
		"code": "ml"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f2.svg",
		"country": "Myanmar (Burma)",
		"code": "mm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f3.svg",
		"country": "Mongolia",
		"code": "mn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f4.svg",
		"country": "Macao Sar China",
		"code": "mo"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f5.svg",
		"country": "Northern Mariana Islands",
		"code": "mp"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f6.svg",
		"country": "Martinique",
		"code": "mq"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f7.svg",
		"country": "Mauritania",
		"code": "mr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f8.svg",
		"country": "Montserrat",
		"code": "ms"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f9.svg",
		"country": "Malta",
		"code": "mt"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fa.svg",
		"country": "Mauritius",
		"code": "mu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fb.svg",
		"country": "Maldives",
		"code": "mv"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fc.svg",
		"country": "Malawi",
		"code": "mw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fd.svg",
		"country": "Mexico",
		"code": "mx"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fe.svg",
		"country": "Malaysia",
		"code": "my"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ff.svg",
		"country": "Mozambique",
		"code": "mz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1e6.svg",
		"country": "Namibia",
		"code": "na"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1e8.svg",
		"country": "New Caledonia",
		"code": "nc"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ea.svg",
		"country": "Niger",
		"code": "ne"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1eb.svg",
		"country": "Norfolk Island",
		"code": "nf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ec.svg",
		"country": "Nigeria",
		"code": "ng"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ee.svg",
		"country": "Nicaragua",
		"code": "ni"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f1.svg",
		"country": "Netherlands",
		"code": "nl"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f4.svg",
		"country": "Norway",
		"code": "no"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f5.svg",
		"country": "Nepal",
		"code": "np"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f7.svg",
		"country": "Nauru",
		"code": "nr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1fa.svg",
		"country": "Niue",
		"code": "nu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ff.svg",
		"country": "New Zealand",
		"code": "nz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f4-1f1f2.svg",
		"country": "Oman",
		"code": "om"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1e6.svg",
		"country": "Panama",
		"code": "pa"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ea.svg",
		"country": "Peru",
		"code": "pe"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1eb.svg",
		"country": "French Polynesia",
		"code": "pf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ec.svg",
		"country": "Papua New Guinea",
		"code": "pg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ed.svg",
		"country": "Philippines",
		"code": "ph"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f0.svg",
		"country": "Pakistan",
		"code": "pk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f1.svg",
		"country": "Poland",
		"code": "pl"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f2.svg",
		"country": "St. Pierre & Miquelon",
		"code": "pm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f3.svg",
		"country": "Pitcairn Islands",
		"code": "pn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f7.svg",
		"country": "Puerto Rico",
		"code": "pr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f8.svg",
		"country": "Palestinian Territories",
		"code": "ps"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f9.svg",
		"country": "Portugal",
		"code": "pt"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1fc.svg",
		"country": "Palau",
		"code": "pw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f5-1f1fe.svg",
		"country": "Paraguay",
		"code": "py"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f6-1f1e6.svg",
		"country": "Qatar",
		"code": "qa"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f7-1f1ea.svg",
		"country": "Réunion",
		"code": "re"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f7-1f1f4.svg",
		"country": "Romania",
		"code": "ro"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f7-1f1f8.svg",
		"country": "Serbia",
		"code": "yu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f7-1f1fa.svg",
		"country": "Russia",
		"code": "ru"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f7-1f1fc.svg",
		"country": "Rwanda",
		"code": "rw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e6.svg",
		"country": "Saudi Arabia",
		"code": "sa"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e7.svg",
		"country": "Solomon Islands",
		"code": "sb"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e8.svg",
		"country": "Seychelles",
		"code": "sc"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e9.svg",
		"country": "Sudan",
		"code": "sd"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ea.svg",
		"country": "Sweden",
		"code": "se"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ec.svg",
		"country": "Singapore",
		"code": "sg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ed.svg",
		"country": "St. Helena",
		"code": "sh"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ee.svg",
		"country": "Slovenia",
		"code": "si"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ef.svg",
		"country": "Svalbard & Jan Mayen",
		"code": "sj"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f0.svg",
		"country": "Slovakia",
		"code": "sk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f1.svg",
		"country": "Sierra Leone",
		"code": "sl"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f2.svg",
		"country": "San Marino",
		"code": "sm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f3.svg",
		"country": "Senegal",
		"code": "sn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f4.svg",
		"country": "Somalia",
		"code": "so"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f7.svg",
		"country": "Suriname",
		"code": "sr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f8.svg",
		"country": "South Sudan",
		"code": "ss"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f9.svg",
		"country": "São Tomé & Príncipe",
		"code": "st"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fb.svg",
		"country": "El Salvador",
		"code": "sv"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fd.svg",
		"country": "Sint Maarten",
		"code": "sx"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fe.svg",
		"country": "Syria",
		"code": "sy"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ff.svg",
		"country": "Eswatini",
		"code": "sz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e6.svg",
		"country": "Tristan Da Cunha",
		"code": "sh"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e8.svg",
		"country": "Turks & Caicos Islands",
		"code": "tc"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e9.svg",
		"country": "Chad",
		"code": "td"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1eb.svg",
		"country": "French Southern Territories",
		"code": "tf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ec.svg",
		"country": "Togo",
		"code": "tg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ed.svg",
		"country": "Thailand",
		"code": "th"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ef.svg",
		"country": "Tajikistan",
		"code": "tj"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f0.svg",
		"country": "Tokelau",
		"code": "tk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f1.svg",
		"country": "Timor-Leste",
		"code": "tl"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f2.svg",
		"country": "Turkmenistan",
		"code": "tm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f3.svg",
		"country": "Tunisia",
		"code": "tn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f4.svg",
		"country": "Tonga",
		"code": "to"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f7.svg",
		"country": "Turkey",
		"code": "tr"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f9.svg",
		"country": "Trinidad & Tobago",
		"code": "tt"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1fb.svg",
		"country": "Tuvalu",
		"code": "tv"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1fc.svg",
		"country": "Taiwan",
		"code": "tw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ff.svg",
		"country": "Tanzania",
		"code": "tz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fa-1f1e6.svg",
		"country": "Ukraine",
		"code": "ua"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fa-1f1ec.svg",
		"country": "Uganda",
		"code": "ug"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f2.svg",
		"country": "U.S. Outlying Islands",
		"code": "um"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f3.svg",
		"country": "United Nations",
		"code": "un"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f8.svg",
		"country": "United States",
		"code": "us"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fa-1f1fe.svg",
		"country": "Uruguay",
		"code": "uy"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fa-1f1ff.svg",
		"country": "Uzbekistan",
		"code": "uz"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fb-1f1e6.svg",
		"country": "Vatican City",
		"code": "va"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fb-1f1e8.svg",
		"country": "St. Vincent & Grenadines",
		"code": "vc"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ea.svg",
		"country": "Venezuela",
		"code": "ve"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ec.svg",
		"country": "British Virgin Islands",
		"code": "vg"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ee.svg",
		"country": "U.S. Virgin Islands",
		"code": "vi"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fb-1f1f3.svg",
		"country": "Vietnam",
		"code": "vn"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fb-1f1fa.svg",
		"country": "Vanuatu",
		"code": "vu"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fc-1f1eb.svg",
		"country": "Wallis & Futuna",
		"code": "wf"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fc-1f1f8.svg",
		"country": "Samoa",
		"code": "ws"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fd-1f1f0.svg",
		"country": "Kosovo",
		"code": "xk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fe-1f1ea.svg",
		"country": "Yemen",
		"code": "ye"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1fe-1f1f9.svg",
		"country": "Mayotte",
		"code": "yt"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ff-1f1e6.svg",
		"country": "South Africa",
		"code": "za"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ff-1f1f2.svg",
		"country": "Zambia",
		"code": "zm"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f1ff-1f1fc.svg",
		"country": "Zimbabwe",
		"code": "zw"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg",
		"country": "England",
		"code": "uk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg",
		"country": "Scotland",
		"code": "uk"
	},
	{
		"flag": "https://twemoji.maxcdn.com/2/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg",
		"country": "Wales",
		"code": "uk"
	}
]


