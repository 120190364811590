export const requestUrl = {
    getAdminRolesAndPermission: 'admin/role-and-permissions/?page=1&page_size=30',
    adminChangePswd: 'account/change-password',
    postAdminProfile: 'admin/profile',
    postAdminRole: 'admin/roles/',
    getAdminRoleById: 'admin/roles/',
    getShopCategory: 'shop/category?page=',
    getTicketDetail: 'admin/ticket/',
    // From Cors Update
    getTicketCategory: 'admin/ticket-category/?page=1&page_size=30',
    getShopOrderList: `shop/orders?page=`,
    getNotification: `account/notification/?page=`,
    readNotification: `account/notification/`,
    getAdminInternalTeam: 'admin/internal-team/',
    getOrderdetail: `shop/orders?order_id=`,
    importIndividual: 'admin/customer/import/individual/',
    importBusiness: `admin/customer/import/business/`,
    importReseller: `admin/customer/import/reseller/`,
    importSubDealer: `admin/customer/import/sub_dealer/`,
    getChatHistory: `customer/conversation/history/`,
    getRequestDetail: `file/service/request/detail/`,
    getRequestList: `file/service/request/list`,
    getRequestListBySearch: `file/service/request/files/search?search=`,
    getRequestListByDateSearch: `file/service/request/files/search/date`,
    getArticleDetail: `zoho/article/detail/`,
    getAdminDasboardData: `dashboard/internal`,
    updateFileCredit: `file/service/file-credit/update`,
    getCustomerCredit: `customer/credit`,
    getAdminDirectoryList: `admin/main/directory/list`,
    addDirectoryFolder: `admin/main/directory/create?folder_path=Main/`,
    deleteDirectoryOrFile: `admin/main/directory/file/delete?filepath=Main/`,
    downloadDirectoryFile: `admin/main/directory/file/download`,
    renameFileFolder: `admin/main/directory/file/rename`,
    directoryFileUpload: `admin/main/directory/file/upload`,
    getMainDirectories: `admin/main/directory`,
    getSubDirectories: `admin/sub/directory/`,
    createVehicleModel: `file/service/vehicle/model/create`,
    ecuVersion: `admin/vehicle/ecu-version`,
    ecuBrand: `admin/vehicle/ecu-brand`,
    decodeFile: `file-operation/decode/`,
    getEncodeModified: `file-operation/encode/modified/`,
    getEncodeFileDetail: `file-operation/encode/kessv2/file/`,
    downloadAnyFile: `file-operation/download/any/file`,
    encodeKtagFile: `file-operation/encode/ktag/file/`,
    getCustomerByUuid: `admin/customer/`,
    updateProfileImg: `account/profile/`,
    getChat: `customer/conversation/`,
    kess3EncodeNow: `file-operation/encode/kess3/file/`,
    mainDirectorySync: `admin/main/directory/sync`,
    getDetailByDecodeid: `file-operation/decode/`,
    updateZohoCredential: `zoho/add/credentials`,
    updateZohoCategories: `zoho/update/categories`,
    updateZohoArticles: `zoho/update/articles`,
    getFileHistory: `file/service/request/history/`,
    fileDownloadList: `file/service/request/files/download/`,
    deleteDirectoryFile: `admin/main/directory/delete/`,
    getEcuBrandList: `file/service/vehicle/ecu/brand/list`,
    getEcuVersionList: `file/service/vehicle/ecu/version/list/`,
    ecuVersionUpdate: `file/service/vehicle/ecu/version/update`,
    createEcuVersion: `file/service/vehicle/ecu/version/create`,
    createTicketNote: `admin/ticket/note/create`,
    getAllNotes: `admin/ticket/note/list/`,
    addReseller: `admin/reseller/`,
    addSubDealer: `admin/dealer/`,
    getPurchaseHistoryAdmin: `customer/purchase-history/admin?uuid=`,
    getSavedTicketFilter: `/admin/custom/filter?module_name=ticket`,
    getResellerList: `admin/reseller/customer/list`,
    geterrorDetail: `file/service/request/files/error/list?file_request_id=`,
    // From Cors Update
    getTicketCategoryList: `admin/ticket-category/`,
    addVehicleViaCSV: `admin/vehicle/import/csv`,
    categoryChart: `dashboard/internal/technical/vehicle_tuned/category?range=`,
    supportTicketChart: `dashboard/internal/ticket/by_status/activity?range=`,
    ReportFileHnadling: `dashboard/internal/file_request/success/number?month=`,
    SendAveragehandling: `dashboard/internal/file_request/success/average?month=`,
    failureByReportNumber: `dashboard/internal/file_request/failure/number?month=`,
    failureByReportAverage: `dashboard/internal/file_request/failure/average?month=`,
    manualHandlingTime: `dashboard/internal/file_request_by/average/time?month=`,
    getCreditPurchased: `dashboard/internal/management/stats`,
    getDealerPerformance: `dashboard/internal/management/dealer/used_keys`,
    decodeFileDownload: `file-operation/view/manual/directory/`,
    getRequestDirectory: `file/service/request/directory/`,
    getInvoiceHistory: `customer/zoho/admin/invoice_history/`,
    deleteCustomer: `account/customer/delete/`,
    deleteUser: `account/internal_user/delete/`,
    getDirectoryOnVehicleSearch: `admin/main/directory/filter/vehicle?`,
    getDirectoryByEcuSearch: `admin/main/directory/filter_by_ecu?`,
    updateOnStopOfUser: `account/internal_user/on_stop/`,
    updateOnStopOfCustomer: `account/customer/on_stop/`,
    downloadLogReportByDateFilter: `admin/logs/csv/download`,
    adminDashboardPermissionManage: `admin/dashboard/manage`,
    shareTemplate: `admin/template/share?template_id=`,

    getResellerCredit:`customer/reseller-credit`,

    
    
    
    // Sync
    syncVehicleData :`file/service/sync-vehicle-data/`
}